<template>
    <v-row no-gutters>
        <v-col cols="10">
            <div class="" align="center" jusitfy="center">
                <v-container class="iframe-wrapper">
                    <div class="iframe-container">
                        <iframe allow="camera; microphone" id="kioskApp" src="https://dekalb-kiosks-demo.netlify.app"
                            frameborder="1px"></iframe>
                        <span>{{ innWidth }}</span>
                    </div>
                </v-container>
            </div>
        </v-col>
        <v-col cols="2" align-self="center" class="px-2">
            <v-row no-gutters class="pa-2">
                <v-col cols="12">
                    <v-btn @click="logout()" color="error" class="ma-2" block>Click to Reload</v-btn>
                </v-col>
                <!-- <v-col cols="12">
                    <v-btn color="primary" class="ma-2" target="_blank" block
                        href="https://netorg18496.sharepoint.com/:x:/s/ARS/EXu18ZwjI_RPgfOI1aUFh9AB_PD1p1iqK5jFcegvWy4slA?e=9YRr6J">Add
                        Unanswered Questions</v-btn>
                </v-col> -->
                <v-col cols="12">
                    <span class="ma-2">Login Credentials:</span>
                </v-col>
                <v-col cols="12">
                    <span class="ma-2">Email: kiosk1@arsconnect.com</span>
                </v-col>
                <v-col cols="12">
                    <span class="ma-2">Password: backspace</span>
                </v-col>
                <vcol cols="12" class="ma-2">
                    <span>Clerk's office Login</span>
                </vcol>
                <v-col cols="12">
                    <span class="ma-2">Email: clerkstagekiosk@arsconnect.com</span>
                </v-col>
                <v-col cols="12">
                    <span class="ma-2">Password: backspace</span>
                </v-col>

                <v-col cols="12">
                    <v-alert dense type="info" class="ma-2">
                        You may have to referesh the Application twice if you are unable to see FAQs/Maps
                        options after you login.
                    </v-alert>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'kioskSimulator',
    data() {
        return {
            innWidth: null,
        };
    },
    methods: {
        logout() {
            var iframe = document.getElementById("kioskApp")
            var src = iframe.src;
            iframe.src = src;
        },
    },
    mounted() {
        this.innWidth = window.innerWidth / 1.5;
    },
}
</script>
<style scoped>
.iframe-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    /* Full height of the viewport */
    overflow: hidden;
}

.iframe-container iframe {
    width: 1920px;
    height: 1080px;
    transform: scale(0.6);
    border: none;
    /* Optional: removes the default border around the iframe */
}

@media screen and (max-width: 2600px) {
    .iframe-container iframe {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 2000px) {
    .iframe-container iframe {
        transform: scale(0.7);
    }
}

/* For 14" MacBook screens (or similar) */
@media screen and (max-width: 1600px) {
    .iframe-container iframe {
        transform: scale(0.6);
    }
}
</style>